import { FaqService } from '@app/features/faq/faq.service'
import { DisplayedFaq } from '@app/features/faq/shared/types'
import { of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'

import { inject } from '@angular/core'
import { ResolveFn, Router } from '@angular/router'

import { LoggerService } from 'src/app/core/services/logger.service'

export const faqResolver: ResolveFn<DisplayedFaq[]> = () => {
  const faqService = inject(FaqService)
  const loggerService = inject(LoggerService)
  const router = inject(Router)

  return faqService.getCategories().pipe(
    switchMap((categoriesResponse) => {
      const ids = categoriesResponse.data.map((category) => category.id)

      return faqService.getFaqItems(ids).pipe(
        map((faqItemsResponse) => {
          const result: DisplayedFaq[] = categoriesResponse.data.map((category) => ({
            ...category,
            items: faqItemsResponse.data.filter((item) => item.category?.id === category.id),
            setLang(lang: string): void {
              this.locale = lang
            },
          }))

          loggerService.log('faqResolver:', result)

          return result
        }),
      )
    }),
    catchError(() => {
      router.navigateByUrl('/')

      return of([])
    }),
  )
}
