import { Observable } from 'rxjs'

import { FaqCategory, FaqItem } from './shared/types'

import { Injectable, inject } from '@angular/core'

import { ApiService } from 'src/app/core/abstracts/api.service'
import { ResponseInterface } from 'src/app/core/interfaces/response'
import { LangService } from 'src/app/core/services/lang.service'

@Injectable({
  providedIn: 'root',
})
export class FaqService extends ApiService {
  private langService: LangService = inject(LangService)

  getCategories(): Observable<ResponseInterface<FaqCategory>> {
    return this.getList<FaqCategory>('/front/faq-category', { take: 50, skip: 0 }, (itemData: FaqCategory) =>
      FaqCategory.fromJson<FaqCategory>(itemData, this.langService),
    )
  }

  getFaqItems(ids: number[]): Observable<ResponseInterface<FaqItem>> {
    return this.getList<FaqItem>('/front/faq', { take: 10, skip: 0, filter: `{category:[${ids.join(',')}]}` }, (itemData: FaqItem) =>
      FaqItem.fromJson<FaqItem>(itemData, this.langService),
    )
  }

  getFaqItem(id: number): Observable<FaqItem> {
    return this.getById<FaqItem>('/front/faq', id, (itemData: FaqItem) => FaqItem.fromJson<FaqItem>(itemData, this.langService))
  }
}
