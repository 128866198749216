import { TranslatableModel } from 'src/app/core/abstracts/translate-model'

export interface FaqBlock {
  title: string
  paragraphs: FaqParagraph[]
}

export interface FaqParagraph {
  type: 'video' | 'text' | 'list' | 'note'
  items?: string[]
  text?: string
  videoUrl?: string
}

export class FaqCategory extends TranslatableModel<FaqCategoryTrslationData> {
  id!: number
  slug!: string
}

export interface FaqCategoryTrslationData {
  question: string
}

export interface FaqItemTrslationData {
  question: string
  answer: string
}

export class FaqItem extends TranslatableModel<FaqItemTrslationData> {
  id!: number
  slug!: string
  category?: FaqItemCategory
}

export interface FaqItemCategory {
  id: number
  slug: string
}

export interface DisplayedFaq extends FaqCategory {
  items: FaqItem[]
}
